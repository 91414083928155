<template>
    <div class="profile_setting_wrap">
        <div class="ps_header">
            <div class="ps_h_bottom">
                <h3>{{ $t('profile_background') }}</h3>
                <button class="reset_btn"
                        @click="[changeType('backgroundFile'), handleFileRemove(backgroundFile[0], [])]"
                        v-if="backgroundFile.length > 0">{{ $t('reset') }}
                </button>
            </div>
        </div>
        <div class="ps_img_wrap">
            <div class="" @click="[changeType('backgroundFile')]">
                <el-upload
                        action=""
                        class="img_wrap w100p"
                        list-type="picture-card"
                        :file-list="backgroundFile"
                        :auto-upload="false"
                        :show-file-list="false"
                        :on-change="onlyOnePhotoChange"
                >
                    <button class="img_box" v-if="backgroundFile.length === 0"></button>
                    <button v-else class="img_box" style="background-image: none;">
                        <!--                        <img :src="returnBackground(backgroundFile[0])" v-if="backgroundFile.length > 0" alt="">-->
                        <img :src="backgroundFile[0].org_url" v-if="backgroundFile.length > 0" alt="">
                    </button>
                </el-upload>
            </div>

            <div class="profile_pic_wrap">
                <div class="in_block" @click="[changeType('profileFile')]">
                    <el-upload
                            action=""
                            class="img_wrap"
                            list-type="picture-card"
                            :file-list="profileFile"
                            :auto-upload="false"
                            :show-file-list="false"
                            :on-change="onlyOnePhotoChange"
                    >
                        <button class="profile_pic" v-if="profileFile.length === 0"></button>
                        <button v-else class="profile_pic" style="background-image: none;">
                            <img :src="profileFile[0].org_url" v-if="profileFile.length > 0" alt="">
                        </button>
                    </el-upload>
                </div>
                <!--                <div class="profile_pic"></div>-->
                <div class="text_wrap">
                    <h3>{{ $t('profile_image') }}</h3>
                    <button class="reset_btn" @click="[changeType('profileFile'), handleFileRemove(profileFile[0], [])]"
                            v-if="profileFile.length > 0">{{ $t('reset') }}
                    </button>
                </div>
            </div>
        </div>

        <!--        <div class="ps_back_wrap">
                    <div class="ps_back_header">
                        <h3>프로필 배경 Small</h3>
                        <button class="reset_btn" @click="[changeType('thumbnailFile'), handleFileRemove(thumbnailFile[0], [])]" v-if="thumbnailFile.length > 0">초기화</button>
                    </div>
                    <div class="in_block" @click="[changeType('thumbnailFile')]">
                        <el-upload
                            action=""
                            class="img_wrap"
                            list-type="picture-card"
                            :file-list="profileFile"
                            :auto-upload="false"
                            :show-file-list="false"
                            :on-change="onlyOnePhotoChange"
                        >
                            <button class="img_box" v-if="thumbnailFile.length === 0" ></button>
                            <button v-else class="img_box" style="background-image: none;">
                                <img :src="thumbnailFile[0].org_url" v-if="thumbnailFile.length > 0" alt="">
                            </button>
                        </el-upload>
                    </div>
        &lt;!&ndash;            <div class="img_box"></div>&ndash;&gt;

                    <div class="profile_pic_wrap">
                        <div class="profile_pic"></div>
                        <div class="nickname">{{ memberData.mb_nick }}</div>
                    </div>
                </div>-->

        <div class="ps_sns_wrap">
            <h3>SNS</h3>
            <div class="input_wrap">
                <div class="input_box first_input"
                     v-for="(urlValue, index) in urlList" :key="`snsUrl${index}`"
                     :class="{'second_input': index > 0 && index+1 === urlList.length}">
                    <el-input :placeholder="$t('http_exp')" v-model="urlList[index]"
                              @input="setUrlData(index, urlList[index])"></el-input>
                    <button class="profile_plus_btn" @click="addUrl()"
                            v-if="index+1 === urlList.length && urlList.length < maxCnt"></button>
                    <button class="profile_minus_btn" @click="minusUrl(index, urlList[index])"
                            v-if="urlList.length > 1"></button>
                </div>
                <div class="validation" v-if="errorMsg != ''">{{ errorMsg }}</div>
                <div class="validation" v-else-if="validation.hasError('sns1')">{{
                        validation.firstError('sns1')
                    }}
                </div>
                <div class="validation" v-else-if="validation.hasError('sns2')">{{
                        validation.firstError('sns2')
                    }}
                </div>
                <div class="validation" v-else-if="validation.hasError('sns3')">{{
                        validation.firstError('sns3')
                    }}
                </div>
                <!--                <div class="input_box second_input">
                                    <el-input placeholder="http://" v-model="snsAddress2"></el-input>
                                    <div class="profile_plus_btn"></div>
                                    <div class="profile_minus_btn"></div>
                                </div>-->
            </div>
        </div>

        <div class="writer_info_wrap">
            <h3>{{ $t('Introduction_writers') }}</h3>
            <el-input type="textarea" v-model="introduce" @input="parentChange('introduce', introduce)"
                      maxLength="200"></el-input>
        </div>

        <div class="p_update_btn_wrap">
            <button class="edit_btn" @click="checkAllSetSns()">{{ $t('save') }}</button>
            <!--            <div class="validation" v-if="errorMsg != '' || validation.hasError('sns1') || validation.hasError('sns2') || validation.hasError('sns3')">
                            {{ errorMsg }}
                        </div>-->
        </div>
    </div>
</template>

<script>
import elFileMixins from "@/mixins/elFileMixins";
import memberValidator from "@/mixins/validators/memberValidator";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
import imageOption from "@/mixins/imageOption";
import imageResizeMixins from "@/mixins/imageResizeMixins";

export default {
    name: "MemberWriteProfileSettingLayout",
    mixins: [elFileMixins, memberValidator, imageOption, imageResizeMixins],
    components: {},
    inject: ['memberSetData'],
    provide() {
        return {}
    },
    props: {
        memberData: {
            default: () => {
            }
        },
    },
    data() {
        return {
            errorMsg: '',
            snsAddress1: '',
            snsAddress2: '',

            maxCnt: 3,
            urlList: [],
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.initSetData();
    },
    mounted() {
        EventBus.$on('MemberWriteProfileSettingLayoutValidator', this.runValidator)
    },
    beforeDestroy() {
        EventBus.$off('MemberWriteProfileSettingLayoutValidator')
    },
    destroyed() {
    },
    computed: {},
    methods: {
        initSetData() {
            if (util.isEmpty(this.memberData)) {
                return false;
            }
            this.introduce = this.memberData.mb_introduce;
            this.parentChange('introduce', this.introduce);
            if (!util.isEmpty(this.memberData.mb_sns_1)) {
                this.urlList.push(this.memberData.mb_sns_1);
                this.setUrlData(this.urlList.length - 1, this.memberData.mb_sns_1)
            }
            if (!util.isEmpty(this.memberData.mb_sns_2)) {
                this.urlList.push(this.memberData.mb_sns_2);
                this.setUrlData(this.urlList.length - 1, this.memberData.mb_sns_2)
            }
            if (!util.isEmpty(this.memberData.mb_sns_3)) {
                this.urlList.push(this.memberData.mb_sns_3);
                this.setUrlData(this.urlList.length - 1, this.memberData.mb_sns_3)
            }
            if (this.urlList.length === 0) {
                this.urlList.push("");
            }
            if (util.isEmpty(this.memberData.Files)) {
                return false;
            }
            let memberFiles = this.memberData.Files;
            if (!util.isEmpty(memberFiles.Profile)) {
                let files = memberFiles.Profile.filter(file => !util.isEmpty(file.a_idx)).slice();
                if (files.length > 0) {
                    files[0].org_url = `${files[0].org_url}?${this.setImageOptions(172, 172, 'jpg')}`;
                    this.profileFile = files;
                    //this.profileFile = memberFiles.Profile.filter(file => !util.isEmpty(file.a_idx));
                }

            }
            if (!util.isEmpty(memberFiles.ProfileBack)) {
                let files = memberFiles.ProfileBack.filter(file => !util.isEmpty(file.a_idx)).slice();
                if (files.length > 0) {
                    files[0].org_url = `${files[0].org_url}?${this.setImageOptions(1200, 310, this.returnExtension(files[0].org_url))}`;
                    this.backgroundFile = files;
                    //this.backgroundFile = memberFiles.ProfileBack.filter(file => !util.isEmpty(file.a_idx));
                }
            }
            if (!util.isEmpty(memberFiles.ProfileThumb)) {
                let files = memberFiles.ProfileThumb.filter(file => !util.isEmpty(file.a_idx)).slice();
                if (files.length > 0) {
                    files[0].org_url = `${files[0].org_url}?${this.setImageOptions(180, 180, this.returnExtension(files[0].org_url))}`;
                    this.thumbnailFile = files;
                    //this.thumbnailFile = memberFiles.ProfileThumb.filter(file => !util.isEmpty(file.a_idx));
                }

            }
        },
        closeModal(name) {
            this.$modal.hide(name);
        },
        parentChange(type, value) {
            this.memberSetData(type, value)
        },
        addUrl() {
            this.urlList.push("");
            this.errorMsg = "";
        },
        minusUrl(index, value) {
            this.urlList.splice(index, 1);
            this.sns1 = "";
            this.sns2 = "";
            this.sns3 = "";
            util.Array.each(this.urlList, (url, index, arr) => {
                this[`sns${index + 1}`] = url;
            })
            this.parentChange(`sns1`, this.sns1);
            this.parentChange(`sns2`, this.sns2);
            this.parentChange(`sns3`, this.sns3);
            this.errorMsg = "";
        },
        setUrlData(index, value) {
            let typeName = `sns${index + 1}`;
            this[typeName] = value;
            this.parentChange(typeName, value);
            this.errorMsg = "";
        },
        runValidator(validatorArr) {
            this.$validate(['sns1', 'sns2', 'sns3']);
        },

        checkAllSetSns() {
            let success = true;
            if (this.urlList.length > 1) {
                util.Array.each(this.urlList, (url, index, arr) => {
                    if (util.isEmpty(url)) {
                        success = false;
                    }
                });
            }
            if (!success) {
                this.errorMsg = this.$t('sns_url_format_err1')
                return false;
            }
            this.runWrite()
        },
        runWrite() {
            EventBus.$emit('updateMember');
        },

    },
    watch: {},
}
</script>

<style scoped>

</style>
